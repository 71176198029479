import React, { useState } from "react";
import { UpCarpet } from "../../icons";
import { getCharacters, userName } from "../../utils/utils";
import { useSelector } from "react-redux";
import kfcBot from "../../assets/img/kfcbot.png";
import pizzaBot from "../../assets/img/pizzabot1.png";
import Error from "../Error/Error";

import ReactMarkdown from "react-markdown";
import { createPortal } from "react-dom";

import { IoMdChatbubbles } from "react-icons/io";
import styles from "../../assets/css/markdown.module.css";

import { Modal } from "antd";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
const name = userName();

// const botDimensions = {
//   kfc: { width: 32, height: 32 },
//   pizzahut: { width: 32, height: 32 },
// };

const SingleMsg = ({
  prompt,
  error = "",
  isLoading,
  handleRegenerate,
  requestBased,
  // socketData,
}) => {
  // // console.log("🚀 ~ socketData:", socketData);
  const userAccess = useSelector((state) => state.userAccess);
  const socketData = useSelector((state) => state.messages);

  const [bot, setBot] = useState(
    userAccess.brand === "kfc" ? kfcBot : pizzaBot
  );

  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(32);
  const [height, setHeight] = useState(32);
  const messages = useSelector((state) => state.messages);
  const lastElement = socketData ? socketData[socketData.length - 1] : {};
  // console.log("🚀 ~ lastElement:", lastElement);

  return (
    <div className="border-0 mx-6 mr-10  mt-3 justify-center  socketData">
      <span>
        <div className="tyn-qa-item flex gap-4 w-full">
          <div className="tyn-qa-avatar align-middle ">
            <div className="tyn-media tyn-size-md">
              <div
                className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full  object-contain
              ${
                requestBased === "socket" ? "bg-socket-light" : "bg-primary-red"
              }`}
              >
                <span className="font-medium text-primary-white">{name}</span>
              </div>
            </div>
          </div>
          <div
            className={`self-center ${
              requestBased === "socket" ? "bg-socket-dark" : "bg-primary-black"
            } text-primary-white font-normal text-sm w-full rounded-lg flex justify-between p-[10px]`}
          >
            <p className="text-base font-medium">{prompt}</p>
            <span className="float-right self-center ">
              <UpCarpet width="28" height="28" />
            </span>
          </div>
        </div>
        <div className="tyn-qa-item flex gap-4 w-full mt-3">
          {requestBased !== "socket" && (
            <div className="tyn-qa-avatar">
              <div className="tyn-qa-avatar-wrap">
                <div className="tyn-media tyn-size-md">
                  <img src={bot} alt="Avatar" width={width} height={height} />
                </div>
              </div>
            </div>
          )}
          <div
            className={`ans-block self-center text-primary-black  font-normal p-2 text-sm w-full group relative ${
              requestBased === "socket" ? "flex justify-center ml-10" : ""
            }`}
            // style={{ display: "flex", justifyContent: "space-between" }}
          >
            {isLoading && (
              <div className="w-12 h-12 relative">
                <div className="absolute w-5 h-5 bg-primary-black rounded-full opacity-70 animate-ping"></div>
                <div className="w-5 h-5 bg-primary-black rounded-full"></div>
              </div>
            )}

            {requestBased === "socket" && (
              <span
                style={{ fontSize: "20px" }}
                onClick={() => setIsOpen(true)}
              >
                <IoMdChatbubbles />
              </span>
            )}

            {error && (
              <>
                <Error msg={error.error ? error.error : error.data.detail} />
                {handleRegenerate && (
                  <div className="text-center flex justify-center">
                    <button
                      onClick={() => handleRegenerate()}
                      type="button"
                      className="text-center  mt-5 text-primary-white bg-primary-red hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                    >
                      Regenerate
                    </button>
                  </div>
                )}
              </>
            )}

            {isOpen &&
              createPortal(
                <Modal
                  title="Multi-agent interaction"
                  centered
                  open={isOpen}
                  onOk={() => setIsOpen(false)}
                  onCancel={() => setIsOpen(false)}
                  width={1000}
                  styles={{
                    body: {
                      maxHeight: "550px",
                      overflowY: "auto",
                    },
                  }}
                  footer={null}
                >
                  <div className="flex-1 text-base">
                    <div className=" overflow-y-auto p-4 pb-18">
                      <div>
                        <div className="tyn-qa-item flex gap-4 w-full">
                          <div className="tyn-qa-avatar align-middle ">
                            <div className="tyn-media tyn-size-md">
                              <div
                                className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-primary-red object-contain bg-socket-light`}
                              >
                                <span className="font-medium text-primary-white">
                                  {name}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`self-center  bg-primary-black text-primary-white font-normal text-sm w-full rounded-lg flex justify-between p-[10px]`}
                          >
                            <p className="text-base font-medium">{prompt}</p>
                            <span className="float-right self-center ">
                              <UpCarpet width="28" height="28" />
                            </span>
                          </div>
                        </div>

                        <div className="flex ">
                          <div
                            className="flex-1"
                            style={{
                              margin: "25px 0",
                              marginLeft: "35px",
                            }}
                          >
                            <div className="">
                              {lastElement?.responses?.map(
                                ({ answer, sender }, index) =>
                                  sender !== "userproxy" && (
                                    <div>
                                      <div
                                        style={{
                                          marginRight:
                                            index % 2 === 0 ? "30%" : "0",
                                          marginLeft:
                                            index % 2 === 0 ? "0" : "30%",
                                        }}
                                        key={index}
                                        className={`flex mb-4 cursor-pointer ${
                                          index % 2 === 0
                                            ? "justify-start"
                                            : "justify-end"
                                        }`}
                                      >
                                        {index % 2 === 0 ? (
                                          <>
                                            <div className="tyn-qa-avatar align-middle mx-2">
                                              <div className="tyn-media tyn-size-md">
                                                <div
                                                  className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-primary-black object-contain 	`}
                                                >
                                                  <span className="font-medium text-primary-white ">
                                                    {getCharacters(sender)}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="max-w-lg bg-primary-white  shadow-md rounded-lg p-3 gap-3">
                                              <p className="text-gray-700 text-primary-black">
                                                {/* <ReactMarkdown
                                                  className={styles.markdown}
                                                >
                                                  {answer}
                                                </ReactMarkdown> */}
                                                <ReactMarkdown
                                                  children={answer}
                                                  className={styles.markdown}
                                                  components={{
                                                    code({
                                                      node,
                                                      inline,
                                                      className,
                                                      children,
                                                      ...props
                                                    }) {
                                                      const match =
                                                        /language-(\w+)/.exec(
                                                          className || ""
                                                        );
                                                      return !inline &&
                                                        match ? (
                                                        <SyntaxHighlighter
                                                          children={String(
                                                            children
                                                          ).replace(/\n$/, "")}
                                                          style={okaidia}
                                                          language={match[1]}
                                                          PreTag="div"
                                                          {...props}
                                                        />
                                                      ) : (
                                                        <code
                                                          className={className}
                                                          {...props}
                                                        >
                                                          {children}
                                                        </code>
                                                      );
                                                    },
                                                  }}
                                                />
                                              </p>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className={` max-w-lg bg-socket-dark text-primary-white shadow-lg rounded-lg p-3 gap-3`}
                                            >
                                              {/* <ReactMarkdown
                                                className={styles.markdown}
                                              >
                                                {answer}
                                              </ReactMarkdown> */}
                                              <ReactMarkdown
                                                children={answer}
                                                className={styles.markdown}
                                                components={{
                                                  code({
                                                    node,
                                                    inline,
                                                    className,
                                                    children,
                                                    ...props
                                                  }) {
                                                    const match =
                                                      /language-(\w+)/.exec(
                                                        className || ""
                                                      );
                                                    return !inline && match ? (
                                                      <SyntaxHighlighter
                                                        children={String(
                                                          children
                                                        ).replace(/\n$/, "")}
                                                        style={okaidia}
                                                        language={match[1]}
                                                        PreTag="div"
                                                        {...props}
                                                      />
                                                    ) : (
                                                      <code
                                                        className={className}
                                                        {...props}
                                                      >
                                                        {children}
                                                      </code>
                                                    );
                                                  },
                                                }}
                                              />
                                            </div>
                                            <div className="tyn-qa-avatar align-middle mx-2">
                                              <div className="tyn-media tyn-size-md">
                                                <div
                                                  className={`relative inline-flex items-center justify-center w-8 h-8 overflow-hidden  rounded-full bg-socket-light object-contain 	`}
                                                >
                                                  <span className="font-medium text-primary-white ">
                                                    {getCharacters(sender)}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>,
                document.body
              )}
          </div>
        </div>
      </span>
    </div>
  );
};

export default SingleMsg;
