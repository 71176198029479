import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dfpBaseUrl, env } from "../constants/constants";
import Cookies from "js-cookie";
import { userEmail, userAccess } from "../utils/utils";
import { current } from "@reduxjs/toolkit";

// const access = userAccess();
let access = Cookies.get("activeConfig");

const appId = access;

const email = userEmail();
export const conversationApi = createApi({
  reducerPath: "messageApi",

  baseQuery: fetchBaseQuery({
    baseUrl: dfpBaseUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: env === "development" ? "omit" : "include",
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getConversations: builder.query({
      query: (id) => `conversations/${id ? id : appId}/${email}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ conversation_id }) => ({
                type: "Conversation",
                conversation_id,
              })),
              { type: "Conversation", id: "ConversationsList" },
            ]
          : [{ type: "Conversation", id: "ConversationsList" }],
    }),
    // /rest/v2/realm/conversations/{conversation_id}

    getSocketHistory: builder.query({
      query: (id) => `conversations/${id}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ response_id }) => ({
                type: "SocketResponse",
                response_id,
              })),
              { type: "SocketResponse", id: "SocketResponseList" },
            ]
          : [{ type: "SocketResponse", id: "SocketResponseList" }],
    }),
    getHistory: builder.query({
      query: (id) => `history/${appId}/${email}?conversation_id=${id}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ response_id }) => ({
                type: "Response",
                response_id,
              })),
              { type: "Response", id: "ResponseList" },
            ]
          : [{ type: "Response", id: "ResponseList" }],
    }),
    postConversation: builder.mutation({
      query: (body) => ({
        url: `/submit`,
        method: "POST",
        body: {
          ...body,
          app_uuid: appId,
          yum_id: email,
        },
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          const { data: createdPost } = await queryFulfilled;

          const newData = {
            response_id: createdPost.response_id,
            prompt: createdPost.prompt,
            message: createdPost.message,
            app_uuid: createdPost.app_uuid,
            yum_id: createdPost.yum_id,
            conversation_id: createdPost.conversation_id,
            conversation_name: createdPost.conversation_name,
          };

          if (body.conversation_id) {
            const patchResult = dispatch(
              conversationApi.util.updateQueryData(
                "getHistory",
                body.conversation_id,
                (draft) => {
                  draft.push(newData);
                }
              )
            );
          } else {
            const patchResult = dispatch(
              conversationApi.util.updateQueryData(
                "getConversations",
                "",
                (draft) => {
                  draft.unshift(newData);
                }
              )
            );
          }
        } catch (error) {
          console.log(error);
          // patchResult.undo();
        }
      },
    }),
    // /rest/v2/realm/conversations/{yum_id}
    deleteConversations: builder.mutation({
      query: (body) => ({
        url: `/conversations/${body.email}`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),

      invalidatesTags: [
        { type: "Response", id: "ResponseList" },
        { type: "Conversation", id: "ConversationList" },
        { type: "SocketResponse", id: "SocketResponseList" },
      ],
      async onQueryStarted(body, { dispatch }) {
        try {
          setTimeout(() => {
            dispatch(conversationApi.util.resetApiState());
          }, 300);
        } catch (error) {
          console.log(error, "error");
        }
      },
    }),
    postVoetes: builder.mutation({
      query: (body) => ({
        url: `/vote`,
        method: "PUT",
        body: { ...body, app_uuid: appId, yum_id: email },
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          conversationApi.util.updateQueryData(
            "getHistory",
            body.conversation_id,
            (draft) => {
              const copy = current(draft);

              let reqBody = body;
              const updated = copy.map((item) => {
                const newObj = { ...item };
                if (item.response_id === reqBody.response_id) {
                  newObj["feedback"] = reqBody.feedback;
                  newObj["feedback_details"] = reqBody.feedback_details;
                }
                return newObj;
              });
              Object.assign(draft, updated);
            }
          )
        );

        const patchResult2 = dispatch(
          conversationApi.util.updateQueryData(
            "getSocketHistory",
            body.conversation_id,
            (draft) => {
              const copy = current(draft);

              let reqBody = body;
              const updated = copy.map((item) => {
                const newObj = { ...item };
                const msgs = newObj.messages.map((msg) => {
                  const msgs = { ...msg };

                  if (msg.response_id === reqBody.response_id) {
                    msgs["feedback"] = reqBody.feedback;
                    msgs["feedback_details"] = reqBody.feedback_details;
                  }
                  return msgs;
                });
                newObj["messages"] = msgs;

                return newObj;
              });
              Object.assign(draft, updated);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    pinConversation: builder.mutation({
      query: (conversation_id) => ({
        url: `/conversation_pin/${conversation_id}`,
        method: "PUT",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),

      async onQueryStarted(
        conversation_id,
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          conversationApi.util.updateQueryData(
            "getConversations",
            "",
            (draft) => {
              const copy = current(draft);

              const updated = copy.map((item) => {
                const newObj = { ...item };
                if (item.conversation_id === conversation_id)
                  newObj["pin"] = !item.pin;
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      },
    }),
    deleteConversation: builder.mutation({
      query: (body) => ({
        url: `/delete_conversation/${body.conversation_id}`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          const patchResult = dispatch(
            conversationApi.util.updateQueryData(
              "getConversations",
              body.myParam ? body.myParam : "",
              (draft) => {
                const index = draft.findIndex((item) => {
                  return item.conversation_id === body.conversation_id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          // patchResult.undo();
        }
      },
    }),

    updateTitle: builder.mutation({
      query: (body) => ({
        url: `/conversation/${body.conversation_id}/${body.new_title}`,
        method: "PUT",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),

      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        const patchResult = dispatch(
          conversationApi.util.updateQueryData(
            "getConversations",
            body.myParam ? body.myParam : "",
            (draft) => {
              const copy = current(draft);

              const updated = copy.map((item) => {
                const newObj = { ...item };

                if (item.conversation_id === body.conversation_id) {
                  newObj["conversation_name"] = body.new_title;
                }

                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useLazyGetHistoryQuery,
  usePostConversationMutation,
  useDeleteConversationsMutation,
  usePostVoetesMutation,
  usePinConversationMutation,
  useDeleteConversationMutation,
  useUpdateTitleMutation,
  useLazyGetSocketHistoryQuery,
  useLazyGetConversationsQuery,
  useGetSocketHistoryQuery,
} = conversationApi;
