import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { loginPath } from "../constants/constants";

import filterReducer from "../services/filterService";
import { filterApi } from "../services/filterService";

import { subscribeToCookieChanges, userSlice } from "../services/userSlice";
import { conversationApi } from "../services/conversationService";
import {
  prevSelectedConversationSlice,
  selectedConversationSlice,
} from "../services/selectedConversation";
import logger from "redux-logger";
import Cookies from "js-cookie";
import { socketSlice } from "../services/socketSlice";
import { workforceApi } from "../services/workforceService";
import { useDispatch } from "react-redux";
import { newConversation } from "../services/newConversationSlice";

const MySwal = withReactContent(Swal);

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (
    isRejectedWithValue(action) &&
    action.payload &&
    (action.payload.status === 401 || action.payload.status === 403)
  ) {
    let timerInterval;

    MySwal.fire({
      title: "",
      html: "Your token has expired. You will be redirected to login",
      timer: 3000,
      timerProgressBar: true,
      showConfirmButton: true,
      // showCancelButton: true,
      icon: "error",
      confirmButtonText: `Login`,

      didOpen: () => {
        MySwal.showLoading();
        // const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          // b.textContent = MySwal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === MySwal.DismissReason.timer) {
        window.location.href = loginPath;
      }
    });
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [conversationApi.reducerPath]: conversationApi.reducer,
    [workforceApi.reducerPath]: workforceApi.reducer,
    [filterApi.reducerPath]: filterApi.reducer,
    filters: filterReducer,
    userAccess: userSlice.reducer,
    selectedConversation: selectedConversationSlice.reducer,
    prevSelectedConversation: prevSelectedConversationSlice.reducer,
    newCoversation: newConversation.reducer,
    messages: socketSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      rtkQueryErrorLogger,
      conversationApi.middleware,
      workforceApi.middleware,
      filterApi.middleware
    ),
});

export const useAppDispatch = () => useDispatch();

setupListeners(store.dispatch);
