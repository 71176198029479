import { createSlice } from "@reduxjs/toolkit";

export const newConversation = createSlice({
  name: "newConversation",
  initialState: false, // You can set the initial state as needed
  reducers: {
    setNewConversation: (state, action) => {
      return action.payload;
    },
  },
});

export const { setNewConversation } = newConversation.actions;
