import React, { useState } from "react";

import Sidebar from "../../Components/SideBar/Sidebar";
import Cookies from "js-cookie";

import MessageWrapper from "../../Components/MessageWrapper/MessageWrapper";
import { useLocation } from "react-router-dom";
import SocketWrapper from "../../Components/SocketWrapper/SocketWrapper";
import { useGetConversationsQuery } from "../../services/conversationService";
import { useSelector } from "react-redux";
import WelcomeScreen from "../../Components/WelcomeComponenet/WelcomeScreen";

const HomeScreen = ({ showSidebar, setShowSidebar }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get("id");
  const [newChat, setNewChat] = useState();
  const { data, error, isLoading, refetch } = useGetConversationsQuery(
    myParam ? myParam : ""
  );

  const selectedConversation = useSelector(
    (state) => state.selectedConversation
  );

  const requestBased = Cookies.get("requestBased");

  const [showWelcome, setShowWelcome] = useState(isLoading);
  return (
    <div className="overflow-hidden">
      <div className=" flex flex-row h-screen">
        {/* {!myParam && ( */}
        <Sidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          setShowWelcome={setShowWelcome}
          data={data}
          error={error}
          isLoading={isLoading}
          refetch={refetch}
          newChat={newChat}
          setNewChat={setNewChat}
        />
        {requestBased === "socket" || myParam ? (
          !isLoading && selectedConversation ? (
            <SocketWrapper
              menuOpen={showSidebar}
              showWelcome={showWelcome}
              setShowWelcome={setShowWelcome}
              myParam={myParam}
              newChat={newChat}
              setNewChat={setNewChat}
            />
          ) : (
            isLoading && (
              <main
                className={`w-full lg:w-3/4 fixed left-0 bottom-0  z-1 flex flex-col overflow-auto  transform ease-in-out duration-300 custom-height lg:ml-[24.5%] ${
                  window.mapp ? "top-0" : "top-[91px]"
                } `}
              >
                <WelcomeScreen myParam={myParam} />
              </main>
            )
          )
        ) : (
          <MessageWrapper
            menuOpen={showSidebar}
            showWelcome={showWelcome}
            setShowWelcome={setShowWelcome}
            myParam={myParam}
          />
        )}
      </div>
    </div>
  );
};

export default HomeScreen;
